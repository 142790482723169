import { LineItem, Order, Price, Purchase, SimpleLineItem } from '@/types/schema';
import { round, sum } from 'lodash';
import { cloneDeep, flatten, get, isEmpty, partition, reduce, uniqBy } from 'lodash-es';
import { Pricing } from '../../../p/commerce/pricing';

function getModifiersTotals( lineItems: SimpleLineItem[] ) {
	return lineItems?.map( ( lineItem ) => {
		let total = 0;
		const modifiers = flatten( lineItem.modifierGroups?.map( ( mGroup ) => mGroup.modifiers?.filter( ( modifier ) => modifier?.externalId && lineItem.metadata?.[ modifier?.externalId ] ) ) );
		total += modifiers.reduce( ( sum, modifier ) => sum + ( modifier?.isPercent
			? modifier.value * total / 100
			: ( modifier?.value ?? 0 ) * lineItem.metadata?.[ modifier?.externalId ] ), 0 );
		return total;
	} );
}

function getLineItemsTotals( lineItems: SimpleLineItem[], modifierTotals: number[] ) {
	return lineItems?.filter( ( lineItem ) => !lineItem?.metadata?.refunded ).map( ( lineItem, index ) => {
		let total = lineItem.price * lineItem.quantity + modifierTotals[ index ] * lineItem.quantity;
		const [ discounts, fees ] = partition( lineItem.prices?.filter( ( price ) => !price.metadata?.externalTax ), ( { value } ) => value < 0 );
		total += discounts.reduce( ( sum, price ) => sum + ( price.isPercent
			? price.value * total / 100
			: price.value * price.quantity * lineItem.quantity ), 0 );
		total += fees.reduce( ( sum, price ) => sum + ( price.isPercent
			? price.value * total / 100
			: price.value * price.quantity ), 0 );
		return total;
	} );
}

function getLineItemsTotalsForPurchase( lineItems: SimpleLineItem[] ) {
	return lineItems.map( ( lineItem ) => {
		let total = lineItem.cost || 0;
		const [ discounts, fees ] = partition( lineItem.prices, ( { value } ) => value < 0 );
		total += fees.reduce( ( sum, price ) => sum + ( price.isPercent
			? price.value * total / 100
			: price.value * price.quantity ), 0 ) || 0;
		total += discounts.reduce( ( sum, price ) => sum + ( price.isPercent
			? price.value * total / 100
			: price.value * price.quantity ), 0 ) || 0;
		return total * lineItem.quantity;
	} );
}

function getTaxTotalAndFeesDiscounts( totals: number[],
	lineItems: SimpleLineItem[],
	orderSubtotal: number,
	discounts: any[],
	fees: any[],
	order: Order | Purchase ) {
	const isOrder = 'type' in order;
	let additionalPrices = 0;
	const orderTaxTotal = Math.max( round( totals.reduce( ( sum, total, index ) => {
		const discountTotal = discounts.reduce( ( sum, price ) => sum + ( price.isPercent
			? price.value * total / 100
			: price.value * price.quantity * ( total / orderSubtotal ) ), 0 );
		const subTotal = total + discountTotal;
		const lineItem = lineItems[ index ];
		const lineItemTaxes = isOrder ? lineItem?.prices?.filter( ( price ) => price.metadata?.useTax ) : [];
		const [ percentTaxes, dollarTaxes ] = partition( lineItemTaxes, ( tax ) => tax.isPercent );
		let lineItemsPercentTaxTotal = 0;
		if ( !isEmpty( percentTaxes ) ) {
			lineItemsPercentTaxTotal = percentTaxes.reduce( ( sum, tax ) => sum + tax.value, 0 );
		}
		let lineItemsDollarTaxTotal = 0;
		if ( !isEmpty( dollarTaxes ) ) {
			lineItemsDollarTaxTotal = dollarTaxes.reduce( ( sum, tax ) => sum + tax.value, 0 );
		}
		const feeTotal = fees.reduce( ( sum, price ) => sum + ( price.isPercent
			? price.value * subTotal / 100
			: price.value * price.quantity / totals.length ), 0 );
		additionalPrices += discountTotal + feeTotal;
		return sum + lineItemsDollarTaxTotal * lineItem.quantity + subTotal * ( ( lineItems[ index ].orderTax
			? order.taxPercent
			: lineItems[ index ].tax || lineItemsPercentTaxTotal ) || 0 ) / 100;
	}, 0 ), 2 ), 0 );
	
	return {
		orderTaxTotal,
		additionalPrices,
	};
}

export const addQuantitiesForSameItem = ( lineItems: SimpleLineItem[], id: string = 'item.externalId' ) => {
	if ( isEmpty( lineItems ) ) return [];
	return lineItems.reduce( ( array, lineItem ) => {
		const item = array.find( ( stored ) => get( stored, id ) === get( lineItem, id ) );
		if ( item ) {
			item.soldQuantity = ( item.soldQuantity || 0 ) + ( lineItem.soldQuantity || lineItem.quantity );
		} else {
			array.push( cloneDeep( { ...lineItem, soldQuantity: lineItem.soldQuantity || lineItem.quantity } ) ); // clone to avoid mutation
		}
		return array;
	}, [] as SimpleLineItem[] );
};

export const addReceivedQuantitiesForSameItem = ( lineItems, id: string = 'item.externalId' ) => {
	if ( isEmpty( lineItems ) ) return [];
	return lineItems.reduce( ( array, lineItem ) => {
		const item = array.find( ( stored ) => get( stored, id ) === get( lineItem, id ) );
		if ( item ) {
			item.receivedQuantity = ( item.receivedQuantity || 0 ) + lineItem.receivedQuantity;
		} else {
			array.push( cloneDeep( lineItem ) );
		}
		return array;
	}, [] );
};

export const getTotalAfterDiscounts = ( lineItem: LineItem,
	orderDiscounts: Price[],
	orderSubTotal: number ): number => {
	const modifiersTotal = reduce( flatten( lineItem.modifierGroups?.map( ( mGroup ) => mGroup.modifiers
		?.filter( ( modifier ) => lineItem.metadata?.[ modifier?.externalId ] ) ) ), ( total, modifier ) => {
		total += modifier?.value * lineItem.metadata?.[ modifier?.externalId ] * lineItem.quantity;
		return total;
	}, 0 );
	let total = lineItem.price * lineItem.quantity + ( modifiersTotal || 0 );
	const discounts = lineItem.prices?.filter( ( price ) => price.value <= 0 && !lineItem.metadata?.externalTax );
	
	if ( !isEmpty( discounts ) ) {
		total += discounts.reduce( ( sum, price ) => sum + ( price.isPercent
			? price.value * total / 100
			: price.value * ( price.quantity || 1 ) * lineItem.quantity ), 0 );
	}
	
	if ( !isEmpty( orderDiscounts ) ) {
		total += orderDiscounts.reduce( ( sum, price ) => sum + ( price.isPercent
			? total * ( price.value / 100 )
			: price.value * price.quantity * ( total / orderSubTotal ) ), 0 );
	}
	return total;
};

export const getGrandTotalAndTextTotal = ( order: Order | Purchase ) => {
	const lineItems = order.lineItems;
	const modifierTotals = getModifiersTotals( lineItems );
	const totals = 'type' in order
		? getLineItemsTotals( lineItems, modifierTotals )
		: getLineItemsTotalsForPurchase( lineItems );
	
	const subTotal = round( sum( totals ), 2 );
	
	const [ discounts, fees ] = partition( order.prices?.filter( ( price ) => !price.metadata?.cloverTaxPercent ), ( { value } ) => value < 0 );
	const {
		      orderTaxTotal,
		      additionalPrices,
	      } = getTaxTotalAndFeesDiscounts( totals, lineItems, subTotal, discounts, fees, order );
	return {
		grandTotal: round( subTotal + additionalPrices + orderTaxTotal, 2 ),
		taxTotal  : orderTaxTotal,
	};
};

export const calculations = ( order: Order | Purchase, pricing?: Pricing ) => {
	const lineItems = order.lineItems;
	const modifierTotals = getModifiersTotals( lineItems );
	const totals = 'type' in order
		? getLineItemsTotals( lineItems, modifierTotals )
		: getLineItemsTotalsForPurchase( lineItems );
	const subTotal = round( sum( totals ), 2 );
	
	const { grandTotal, taxTotal } = getGrandTotalAndTextTotal( order );
	
	const orderDiscounts = ( pricing || order ).prices?.filter( ( price ) => price.value <= 0 && !price.metadata?.externalTax );
	const [ orderTaxLineItems, taxableLineItems ] = partition( lineItems, ( lineItem ) => lineItem?.orderTax );
	const [ multiTaxLineItems, singleTaxLineItems ] = partition( taxableLineItems, ( lineItem ) => lineItem?.tax === 0 );
	const [ taxPercentLineItems, nonTaxPercentLineItems ] = partition( singleTaxLineItems, ( lineItem ) => lineItem?.tax === order.taxPercent );
	
	const nonTaxPercentTotals: number[] = nonTaxPercentLineItems?.reduce( ( totals: number[], lineItem: LineItem ) => {
		const total = getTotalAfterDiscounts( lineItem, orderDiscounts, subTotal );
		totals = [ ...totals, total || 0 ];
		return totals;
	}, [] );
	
	const nonTaxPercentLineItemsTaxCal = nonTaxPercentTotals?.filter( Boolean )?.reduce( ( obj, total, index ) => {
		const lineItem = nonTaxPercentLineItems[ index ];
		const tax = lineItem?.tax;
		if ( tax && tax > 0 ) {
			const taxRate = total * tax / 100;
			if ( obj[ tax ] ) {
				obj = { ...obj, [ tax ]: [ tax, obj[ tax ][ 1 ] + taxRate ] };
			} else {
				obj = { ...obj, [ tax ]: [ tax, taxRate ] };
			}
		}
		return obj;
	}, {} );
	
	const nonTaxPercentLineItemsTaxesData = Object.values( nonTaxPercentLineItemsTaxCal )?.filter( Boolean );
	
	const orderTaxTotals: number[] = uniqBy( [ ...taxPercentLineItems, ...orderTaxLineItems ], 'id' )
		?.reduce( ( totals: number[], lineItem: LineItem ) => {
			const total = getTotalAfterDiscounts( lineItem, orderDiscounts, subTotal );
			totals = [ ...totals, total || 0 ];
			return totals;
		}, [] );
	
	const orderTaxCal = orderTaxTotals?.filter( Boolean )?.reduce( ( sum, total ) => {
		sum += total * order.taxPercent / 100;
		return sum;
	}, 0 );
	
	const multiTaxableTotals: number[] = multiTaxLineItems?.reduce( ( totals, lineItem: LineItem ) => {
		const total = getTotalAfterDiscounts( lineItem, orderDiscounts, subTotal );
		totals = [ ...totals, total || 0 ];
		return totals;
	}, [] );
	
	const multiTaxLineItemData = multiTaxableTotals?.filter( Boolean )?.reduce( ( obj, total, index ) => {
		const lineItem = multiTaxLineItems[ index ];
		const lineItemTaxes = lineItem?.prices?.filter( ( price ) => price.metadata?.useTax && price.metadata?.externalId );
		
		if ( !isEmpty( lineItemTaxes ) ) {
			for ( const tax of lineItemTaxes ) {
				const sum = tax.isPercent ? total * tax.value / 100 : tax.value * lineItem.quantity;
				if ( !obj[ tax.metadata.externalId ] ) {
					obj = { ...obj, [ tax.metadata.externalId ]: [ tax.name, tax.value, sum, tax.isPercent ] };
				} else {
					obj = {
						...obj,
						[ tax.metadata.externalId ]: [ tax.name,
						                               tax.value,
						                               obj[ tax.metadata.externalId ][ 2 ] + sum,
						                               tax.isPercent ],
					};
				}
			}
		}
		return obj;
	}, {} );
	const multiTaxesData = Object.values( multiTaxLineItemData )?.filter( Boolean );
	
	return {
		subTotal,
		grandTotal,
		taxTotal,
		nonTaxPercentLineItemsTaxesData,
		orderTaxCal,
		multiTaxesData,
		orderDiscounts,
	};
};

export function getSubTotalAfterOrderDiscount( invoice, discounts: Price[] ) {
	const totalDiscount = discounts?.reduce( ( sum, discount ) => sum + ( discount.isPercent
		? discount.value * invoice.subTotal / 100
		: discount.value * discount.quantity ), 0 );
	return invoice.subTotal + ( totalDiscount || 0 );
}
