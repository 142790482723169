import type { PaymentValidator } from '@/types/schema';
import axios from 'axios';
import { isEmpty, snakeCase, toLower } from 'lodash-es';
import { ECOMMERCE_ENDPOINT, PLATFORM_ENDPOINT } from '../clover';
import type PaymentProcessor from './paymentProcessor';

const cloverPaymentProcessor: PaymentProcessor = {
	type: 'CLOVER',
	
	async getCard( gateway, clientId ) {
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/customers/${clientId}`, {
			params : { expand: 'cards' },
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		return data;
	},
	async getSurcharge( gateway ) {
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/ecomm_payment_configs`, {
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		return data;
	},
	async postTender( gateway, { label, labelKey, visible, enabled, editable } ) {
		const { data } = await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/tenders`, {
			labelKey,
			label,
			enabled,
			visible,
			editable,
		}, {
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		return data;
	},
	
	async getTenders( gateway ) {
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/tenders`, {
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		return data;
	},
	
	async removeCardSource( gateway, clientId, sourceId ) {
		const { data } = await axios.delete( `${ECOMMERCE_ENDPOINT}/customers/${clientId}/sources/${sourceId}`,
			{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		return data;
	},
	
	async deleteCard( gateway, clientId, cardId ) {
		const { data } = await axios.delete( `${PLATFORM_ENDPOINT}/${gateway.externalId}/customers/${clientId}/cards/${cardId}`,
			{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		return data;
	},
	
	async saveCard( gateway, clientEmail, clientId, cardFirstSix, cardLastFour, cardToken ) {
		// revoke the card
		// await axios.delete( `${ECOMMERCE_ENDPOINT}/customers/${clientId}/sources/${cardToken}`,
		// 	{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } ).catch( () => null );
		if ( !cardToken.includes( 'clv' ) ) {
			throw new Error( `Invalid clv token ${cardToken} when saving the card.` );
		}
		const { data } = await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/customers/${clientId}/cards`, {
				first6   : cardFirstSix,
				last4    : cardLastFour,
				token    : cardToken,
				tokenType: 'MULTIPAY',
				customer : { id: clientId },
			},
			{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		
		if ( clientEmail ) {
			try {
				
				await axios.put( `${ECOMMERCE_ENDPOINT}/customers/${clientId}`, {
					email : clientEmail,
					source: cardToken,
				}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
			} catch {
			}
		}
		return data;
	},
	
	async createPayment( gateway, {
		type,
		cardId,
		note,
		amount,
		tip,
		orderId,
		client,
		saveCard,
		staffExternalId,
		cardFirstSix,
		cardLastFour,
		cardToken,
		paymentTaxAmount,
		isClientPage,
	}, reqIp ) {
		const intAmount = Math.round( amount * 100 );
		const intTip = Math.round( tip * 100 );
		const intTaxAmount = Math.round( ( paymentTaxAmount || 0 ) * 100 );
		
		if ( saveCard && client?.externalId && client?.email && cardId ) {
			try {
				if ( cardFirstSix && cardLastFour ) {
					console.log( 'Saving a client card' );
					// revoke card
					// axios.delete( `${ECOMMERCE_ENDPOINT}/customers/${client.externalId}/sources/${cardId}`,
					// 	{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } ).catch( () => null );
					
					if ( !cardId.includes( 'clv' ) ) {
						throw new Error( `Invalid clv token ${cardId} to save this card.` );
					}
					
					await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/customers/${client.externalId}/cards`, {
							first6   : cardFirstSix,
							last4    : cardLastFour,
							token    : cardId,
							tokenType: 'MULTIPAY',
							customer : { id: client.externalId },
							
						},
						{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
					
					try {
						await axios.put( `${ECOMMERCE_ENDPOINT}/customers/${client.externalId}`, {
							email : client.email,
							source: cardId,
						}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
						await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/customers/${client.externalId}`, {
							emailAddresses: [ { emailAddress: client.email } ],
						}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
						
					} catch {
					}
					
				}
				//
				// console.log( 'Updating client card number in clover' );
				// await axios.put( `${ECOMMERCE_ENDPOINT}/customers/${client.externalId}`, {
				// 	email : client.email,
				// 	source: cardId,
				// }, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
				// cardId = client.externalId;
			} catch {
			}
		}
		if ( orderId ) {
			if ( type === 'CARD' || type?.includes( 'SAVED' ) ) {
				console.log( 'Paying with a saved card' );
				
				const { data } = await axios.post( `${ECOMMERCE_ENDPOINT}/orders/${orderId}/pay`, {
					source            : cardToken || cardId || client?.externalId,
					tip_amount        : intTip,
					amount            : intAmount,
					currency          : toLower( gateway.currency ),
					email             : client?.email,
					partial_redemption: true,
					stored_credentials: saveCard || cardToken ? {
						sequence    : 'SUBSEQUENT',
						is_scheduled: false,
						initiator   : 'CARDHOLDER',
					} : undefined,
				}, { headers: { 'Authorization': `Bearer ${gateway.externalKey}`, 'x-forwarded-for': reqIp } } );
				return { id: data.charge };
			} else {
				console.log( 'Processing tenders' );
				const { data: tenders } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/tenders`,
					{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
				let tender = tenders.elements.find( ( tender ) => tender?.labelKey === `com.clover.tender.${snakeCase( toLower( type ) )}` || toLower( tender?.label ) === toLower( type ) );
				
				if ( !tender && toLower( type ) === 'invoice credit' ) {
					try {
						const { data } = await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/tenders`, {
							label   : 'Invoice Credit',
							visible : false,
							enabled : true,
							editable: false,
						}, {
							headers: { Authorization: `Bearer ${gateway.externalKey}` },
						} );
						tender = data;
					} catch {
					}
				}
				// if client is paying and no tender is found,
				if ( isClientPage && !tender ) return null;
				const { data } = await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${orderId}/payments`, {
					amount      : intAmount,
					tipAmount   : intTip,
					taxAmount   : intTaxAmount > 0 ? intTaxAmount : undefined,
					employee    : staffExternalId ? { id: staffExternalId } : undefined,
					cashTendered: type === 'CASH' ? intAmount + intTip : undefined,
					note        : cardId ? `${cardId} - ${note}` : note,
					tender      : { id: tender ? tender.id : tenders.elements[ 0 ].id },
				}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
				return { id: data.id, number: cardId };
			}
		} else {
			if ( !cardId || client?.externalId ) return null;
			console.log( 'Processing charges' );
			const { data } = await axios.post( `${ECOMMERCE_ENDPOINT}/charges`, {
				amount            : intAmount,
				currency          : toLower( gateway.currency ),
				source            : cardToken || cardId || client?.externalId,
				stored_credentials: saveCard || cardToken ? {
					sequence    : 'SUBSEQUENT',
					is_scheduled: false,
					initiator   : 'CARDHOLDER',
				} : undefined,
			}, { headers: { 'Authorization': `Bearer ${gateway.externalKey}`, 'x-forwarded-for': reqIp } } );
			return data;
		}
	},
	async convertPayment( gateway, location, data ) {
		const refundedAmount = data?.refundedAmount;
		const additionalCharges = data?.additionalCharges?.elements;
		
		let checkNumber = '';
		if ( data?.note && data?.note.includes( '#' ) ) {
			const checkNumbers = data.note.split( '#' );
			checkNumber = checkNumbers[ checkNumbers.length - 1 ];
		}
		const cardTransaction = data?.cardTransaction;
		return {
			type           : data.tender?.label || undefined,
			status         : refundedAmount > 0 && refundedAmount >= data.amount
				? 'REFUNDED'
				: refundedAmount > 0 && refundedAmount < data.amount
					? 'PARTIALLY_REFUNDED'
					: data.result === 'SUCCESS' ? 'PAID' : 'FAILED',
			amount         : data.amount / 100,
			tip            : data.tipAmount / 100,
			note           : data.note,
			checkNumber    : checkNumber,
			refundedAmount : refundedAmount / 100,
			gateway        : gateway.id,
			createdAt      : +data?.createdTime ? new Date( +data.createdTime ) : null,
			externalId     : data.id,
			parentPaymentId: data?.parentPaymentId,
			cardBrand      : cardTransaction?.cardType,
			cardEntryType  : cardTransaction?.entryType,
			cardLast4      : cardTransaction?.last4,
			cardFunc       : cardTransaction?.extra?.func,
			prices         : !isEmpty( additionalCharges ) ? additionalCharges.map( ( charge ) => ( {
				name      : charge.type,
				isPercent : !charge?.hasOwnProperty( 'amount' ),
				value     : charge?.amount || charge?.rate / 10000,
				externalId: charge.id,
			} ) ) : [],
		} as PaymentValidator;
	},
	async queryPayment( { paymentId, merchId, merchKey } ) {
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${merchId}/payments/${paymentId}`, {
			headers: { Authorization: `Bearer ${merchKey}` },
		} );
		return data;
	},
	async refundPayment( gateway, { paymentId, amount } ) {
		const { data } = await axios.post( `${ECOMMERCE_ENDPOINT}/refunds`, {
			charge: paymentId,
			amount: Math.round( amount * 100 ),
		}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		return data;
	},
	async refundPartialPayment( gateway, orderExternalId, { paymentId, amount } ) {
		const { data } = await axios.post( `${ECOMMERCE_ENDPOINT}/orders/${orderExternalId}/returns`, {
			charge: paymentId,
			amount: Math.round( amount * 100 ),
		}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		return data;
	},
	
};
export default cloverPaymentProcessor;
