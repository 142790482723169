import axios from 'axios';
import type Processor from './processor';

export const CLOVER_HOST = `https://${process.env.NEXT_PUBLIC_DEV
	? 'sandbox.dev'
	: 'api'}.clover.com`;
export const PLATFORM_ENDPOINT = `${CLOVER_HOST}/v3/merchants`;
export const ECOMMERCE_ENDPOINT = `https://${process.env.NEXT_PUBLIC_DEV
	? 'scl-sandbox.dev'
	: 'scl'}.clover.com/v1`;
export const PLATFORM_APP_ENDPOINT = `${CLOVER_HOST}/v3/apps/${process.env.NEXT_PUBLIC_CLOVER_APP_ID}`;
export const PAY_DISPLAY_ENDPOINT = `${CLOVER_HOST}/connect/v1`;
export const getCloverApi = async ( { mId, token, path }: { mId: string, token: string, path: string } ) =>
	axios.get( `${PLATFORM_ENDPOINT}/${mId}/${path}`, { headers: { Authorization: `Bearer ${token}` } } );

export const postCloverApi = async ( { mId, token, path, body }: {
	mId: string,
	token: string,
	path: string,
	body: any
} ) =>
	axios.post( `${PLATFORM_ENDPOINT}/${mId}/${path}`, body, { headers: { Authorization: `Bearer ${token}` } } );

const cloverProcessor: Processor = {
	type: 'CLOVER',
	
	async connectAccount() {
		return {
			url: `https://${process.env.NEXT_PUBLIC_DEV
				? 'sandbox.dev'
				: 'www'}.clover.com/oauth/authorize?client_id=${process.env.NEXT_PUBLIC_CLOVER_APP_ID}`,
		};
	},
	async disconnectAccount() {
		return { url: 'https://www.clover.com' };
	},
	
};
export default cloverProcessor;
